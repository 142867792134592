import React from "react"
import LegalNotices from "../../components/pages/LegalNotices"
import { graphql } from "gatsby"

const PrivacyPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }
  const lang = "ca"

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "LEGAL"
  })

  return (
    <LegalNotices lang={lang} seoData={seoData} urls={resultUrls} />
  )
}

export const pageQuery = graphql`
  query legalNoticesCaQuery {
    seoDataJson(name: { eq: "legal" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
  }
`

export default PrivacyPolicy
